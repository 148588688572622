import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

import { Per_Page_Dropdown, Select2Data, RegxExpression } from "./common";
import { getCart, updateCartCount } from "./apis/addToCart/addToCart";
import { getWishlist, wishlistContext } from "./apis/Wishlist/Wishlist";
import { getCompare, compareContext } from "./apis/Compare/Compare";

export const Context = createContext();
var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();
const AppContext = ({ children }) => {
  // const IMG_URL = "http://127.0.0.1:8425";
  // const IMG_URL = "http://192.168.16.53:8421";
  const IMG_URL = "http://cmjnode.profcymabackend.com:8425";
  // const IMG_URL = "https://node.cmjindustries.in:8421";

  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [compare, setComparelist] = useState([]);
  const [signin, setSignin] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  // const [isAllow, setisAllow] = useState([]);

  // const [shimmerLoader, setShimmerLoader] = useState(false);
  const [addCartModal, setAddCartModal] = useState(false);
  const minLength = 2;
  const maxLength = 30;

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const [cartCount, setCartCount] = useState(0);
  const [trigger, setTrigger] = useState(0);

  const triggerCartUpdate = () => {
    setTrigger(trigger === 0 ? 1 : 0);
  };

  useEffect(() => {
    updateCartCount(signin, setCart);
    wishlistContext(signin, setWishlist);
    compareContext(signin, setComparelist);
  }, [Cookies.get("cmj_cart"), signin]);

  // console.log("cartCountFrom COntext", cartCount);
  // console.log(usertype);
  // console.log(cart);

  const isTokenValid = async () => {
    const token = Cookies.get("cmj_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("cmj_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        // setisAllow([]);
        navigate("/");
      } else {
        // setisAllow(decodedToken?.permissions);
        // getuserData(decodedToken.user);
        setUsertype(decodedToken.type);
        const cart = await getCart();
        await setCartCount(cart?.data?.length);
        const wishlist = await getWishlist();
        await setWishlist(wishlist?.data);
        setSignin(true);
      }
    } else {
      // setisAllow([]);
      setSignin(false);
      setUsertype("");
      // navigate("/");
    }
  };

  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        RegxExpression,
        // shimmerLoader,
        // setShimmerLoader,
        addCartModal,
        setAddCartModal,
        cartCount,
        triggerCartUpdate,
        wishlist,
        setWishlist,
        wishlistContext,
        compare,
        setComparelist,
        compareContext,
        updateCartCount,
        htmlToReactParser,
        Cookies,
        cart,
        setCart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
