import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Col, Container, Form, Nav, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faArrowRightToBracket,
  faUserPlus,
  faMagnifyingGlass,
  faUser,
  faCartShopping,
  faBars,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
// import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../CommonComponent/SearchBar/SearchBar";

import Dropdown from "react-bootstrap/Dropdown";
import Permision_Delete_Modal from "../CommonComponent/Common_Modal/Permision_Delete_Modal/Permision_Delete_Modal";
import LogOut from "../CommonComponent/Common_Modal/LogOut/LogOut";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
import EditAddress from "../CommonComponent/Common_Modal/EditAddress/EditAddress";

import { Context } from "../../utils/context";
import { AppSetup } from "../../utils/apis/common/Common";
import { updateCartCount } from "../../utils/apis/addToCart/addToCart";
import { ListGroup } from "react-bootstrap";
import { UserType } from "../../utils/common";
import { getSampleRollProducts } from "../../utils/apis/sample_roll";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("home");
  const [activeKey, setActiveKey] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    setActiveKey(null);
    setIsDropdownOpen(false); // Close dropdown on navigation click
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [show, setShow] = useState(false);

  const handleCloseone = () => setShow(false);
  const handleShow = () => setShow(true);
  //  useEffect(() => {
  //         if (isDropdownOpen) {
  //             document.body.classList.add('dropdown-open');
  //         } else {
  //             document.body.classList.remove('dropdown-open');
  //         }
  //     }, [isDropdownOpen]);
  const [modalShow1, setModalShow1] = useState(false);

  //
  // ----------------------------------------------------------------
  const navigate = useNavigate();
  const {
    IMG_URL,
    htmlToReactParser,
    signin,
    setSignin,
    setUsertype,
    usertype,
    Cookies,
    cart,
    setCart,
    wishlist,
    getData,
    Select2Data,
  } = useContext(Context);

  const [appdata, setAppData] = useState([]);

  const getAppData = async () => {
    const res = await AppSetup();
    setAppData(res.data);
  };

  useEffect(() => {
    getAppData();
  }, []);

  // Logout
  // const handleLogout = async () => {
  //   setModalShow(true);
  //   await updateCartCount(false, setCart);
  //   await setSignin(false);
  //   await Cookies.remove("cmj_security", { path: "/" });
  //   await navigate("/");
  // };

  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [product_id, setProductId] = useState([]);

  const getProducts = async () => {
    const res = await getData(
      `/without-login/products?term=${search}&product_type=${usertype}`
    );
    if (res?.success) {
      const result = res?.data?.map((data) => ({
        value: data?.id,
        label: data?.name + " " + data?.short_description,
        name: "product_id",
      }));
      setProducts(result);
      // setProducts(await Select2Data(res?.data, "product_id"));
    }
  };

  // console.log("products :--", products);

  useEffect(() => {
    getProducts();
  }, [search]);

  const handleNavigate = async (id) => {
    await navigate(`/sample-roll/${id}`);
    await setSearch("");
  };

  //

  // ---------------------------------------------------------------------------

  const [data, setData] = useState([]);

  const getSampleRollData = async () => {
    const res = await getSampleRollProducts();
    if (res?.success) {
      setData(res?.data);
    }
  };

  useEffect(() => {
    getSampleRollData();
  }, []);

  return (
    <>
      <section className="Header">
        <div className="Desktop-View">
          {/* sub header  */}
          <div className="sub-header Desktop">
            <div className="container-fluid">
              <div className="col-xl-11 col-lg-12 mx-auto">
                <Row>
                  <Col
                    xxl={5}
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className="header-help-line-number "
                  >
                    <div className="Main-sub-header">
                      <div className="mail">
                        <FontAwesomeIcon icon={faEnvelope} className="icon" />
                        <p className="text">Mail: {appdata?.email}</p>
                      </div>
                      <div className="border-bt"></div>
                      <div className="helpline">
                        <FontAwesomeIcon icon={faPhone} className="icon" />
                        <p className="text">Helpline: {appdata?.contact_no}</p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xxl={4}
                    xl={4}
                    lg={4}
                    md={3}
                    sm={12}
                    xs={12}
                    className="header-cmj-name-holder"
                  >
                    <h1 className="title">CMJ INDUSTRIES PUNE</h1>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={4} sm={12} xs={12}>
                    <div className="Log-main">
                      {signin ? (
                        <Link to={"/profile"}>
                          <div className="Login">
                            <FontAwesomeIcon
                              icon={faCircleUser}
                              className="icon-user"
                            />
                            <p className="text profile">PROFILE</p>
                          </div>
                        </Link>
                      ) : (
                        <Link to={"/login"}>
                          <div className="Login">
                            <FontAwesomeIcon
                              icon={faArrowRightToBracket}
                              className="icon"
                            />
                            <p className="text">LOGIN</p>
                          </div>
                        </Link>
                      )}

                      <div className="border-bt"></div>

                      {signin ? (
                        <div
                          className="Login"
                          onClick={() => setModalShow(true)}
                        >
                          <FontAwesomeIcon
                            icon={faArrowRightToBracket}
                            className="icon"
                          />
                          <p className="text">LOGOUT</p>
                        </div>
                      ) : (
                        <Link to={"/register"}>
                          <div className="Login">
                            <FontAwesomeIcon
                              icon={faUserPlus}
                              className="icon"
                            />
                            <p className="text">REGISTER</p>
                          </div>
                        </Link>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {/* sub header  */}

          {/* search header */}
          <div className="Search-Header Desktop">
            <div className="container-lg container-md-fluid">
              <Row>
                <Col
                  xxl={2}
                  xl={2}
                  lg={2}
                  sm={2}
                  xs={2}
                  className="header-logo-holder"
                >
                  <div className="main-search">
                    <Link to={"/"}>
                      <div className="Logo-main">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/logo/cmj-logo.png"
                          }
                          className="logo"
                        />
                      </div>
                    </Link>
                  </div>
                </Col>
                <Col xxl={7} xl={7} lg={7} sm={7} xs={7}>
                  <div className="search-input">
                    <Form>
                      <Form.Group
                        className="mb-3 sub-text"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search Here.."
                          className="sub-text"
                          onChange={(e) => setSearch(e?.target?.value)}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </Form>
                    <div className="icon-search">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="icon"
                      />
                    </div>
                    {search && (
                      <ListGroup>
                        {products?.length > 0 ? (
                          <>
                            {products?.map((product) => (
                              <ListGroup.Item
                                key={product?.value}
                                onClick={() => handleNavigate(product?.value)}
                              >
                                {product?.label}
                              </ListGroup.Item>
                            ))}
                          </>
                        ) : (
                          <p>No Product Found By this Search</p>
                        )}
                      </ListGroup>
                    )}
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3} sm={3} xs={3}>
                  {isDropdownOpen && <div className="dark-overlay"></div>}
                  <div
                    className={`Cart-shopping ${isDropdownOpen ? "content-under-overlay" : ""
                      }`}
                  >
                    {usertype && (
                      <div className="user-drop">
                        <FontAwesomeIcon icon={faUser} />
                        <Dropdown
                          show={isDropdownOpen}
                          onToggle={toggleDropdown}
                        >
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="sub-title"
                          >
                            My account
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown-open">
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/your-orders");
                              }}
                              // href="/your-orders"
                              className="sub-text"
                            >
                              Order
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/wishlist");
                              }}
                              // href="/wishlist"
                              className="sub-text"
                            >
                              Wish List
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                navigate("/comparelist");
                              }}
                              // href="/comparelist"
                              className="sub-text"
                            >
                              Compared List
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                              onClick={() => {
                                navigate("/voucher");
                              }}
                              className="sub-text"
                            >
                              Vouchers
                            </Dropdown.Item> */}
                            <Dropdown.Item
                              href=""
                              className="sub-text"
                              onClick={() => setModalShow(true)}
                            >
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}

                    <Link to="/cart">
                      <div className="cart">
                        <FontAwesomeIcon
                          icon={faCartShopping}
                          className="icon"
                        />
                        <p className="text sub-title">Cart</p>
                      </div>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          {/* search header */}

          {/* Main Header */}
          <div className="Main-Header Desktop">
            <div className="container-lg container-md-fluid">
              <div className="header-main-cmj">
                {/* First Group of Nav Links */}
                <Nav
                  className={`desktop-offcanvas ${isScrolled ? "scrolled" : ""
                    }`}
                >
                  <div className="main-first">
                    <Nav.Link
                      as={Link}
                      to="/"
                      className={location.pathname === "/" ? "active" : ""}
                      onClick={() => handleNavItemClick("/")}
                    >
                      HOME
                    </Nav.Link>

                    {/* {usertype == UserType.B2BCustomer && ( */}

                    <Nav.Link
                      as={Link}
                      to="/b2bbulkorder"
                      className={
                        location.pathname === "/b2bbulkorder" ? "active" : ""
                      }
                      onClick={() => navigate("/b2bbulkorder")}
                    >
                      B2B BULK ORDER
                    </Nav.Link>

                    {/* )} */}

                    {/* <Nav>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="text"
                          as={Link}
                          // to=""
                        >
                          B2B BULK ORDER
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => navigate("/b2bbulkorder")}
                            // href="/b2bbulkorder"
                            className="sub-text"
                          >
                            Transparent Tape
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => navigate("/b2bbulkorder")}
                            // href="#/action-2"
                            className="sub-text"
                          >
                            Brown Tape
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav> */}

                    {/* <Nav.Link
                      as={Link}
                      to="/sample-roll"
                      className={
                        location.pathname === "/sample-roll" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("/sample-roll")}
                    >
                      SAMPLE ROLLS
                    </Nav.Link> */}

                    <Nav>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="text"
                          as={Link}
                        // to=""
                        >
                          SAMPLE ROLLS
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {data?.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() =>
                                navigate(`/sample-roll/${item.id}`)
                              }
                              // href="/b2bbulkorder"
                              className="sub-text"
                            >
                              {item?.name}
                            </Dropdown.Item>
                          ))}
                          {/* <Dropdown.Item
                            onClick={() => navigate("/b2bbulkorder")}
                            // href="/b2bbulkorder"
                            className="sub-text"
                          >
                            Transparent Tape
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => navigate("/b2bbulkorder")}
                            // href="#/action-2"
                            className="sub-text"
                          >
                            Brown Tape
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav>

                    <Nav.Link
                      as={Link}
                      to="/hotdeals"
                      className={
                        location.pathname === "/hotdeals" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("/hotdeals")}
                    >
                      HOT DEALS
                    </Nav.Link>
                  </div>

                  {/* Second Group of Nav Links */}
                  <div className="second-header">
                    <Nav.Link
                      as={Link}
                      to="/track-order"
                      className={
                        location.pathname === "/track-order" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("/track-order")}
                    >
                      TRACK ORDER
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/faq"
                      className={location.pathname === "/faq" ? "active" : ""}
                      onClick={() => handleNavItemClick("/faq")}
                    >
                      FAQ
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/help"
                      className={location.pathname === "/help" ? "active" : ""}
                      onClick={() => handleNavItemClick("/help")}
                    >
                      HELP
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/contact-us"
                      className={
                        location.pathname === "/contact-us" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("/contact-us")}
                    >
                      CONTACT US
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/cmj-industries"
                      className={
                        location.pathname === "/cmj-industries" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("/cmj-industries")}
                    >
                      WHY CMJ INDUSTRIES?
                    </Nav.Link>
                  </div>
                </Nav>
              </div>
            </div>
          </div>
        </div>
        <div className="Mobile-View-Main">
          <div className="sub-header-mobile">
            <Row className="ps-0 ms-0">
              <Col xxl={6} xl={6} lg={6} md={6} xs={12} sm={6}>
                <div className="mail">
                  <FontAwesomeIcon icon={faEnvelope} className="icon" />
                  <p className="text sub-title">Mail: {appdata?.email}</p>
                </div>

                <div className="helpline">
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                  <p className="text sub-title">
                    Helpline: {appdata?.contact_no}
                  </p>
                </div>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                xs={12}
                sm={6}
                className="register"
              >
                <div className="Log-main">
                  {signin ? (
                    <>
                      {" "}
                      <Link to={"/profile"}>
                        <div className="Login">
                          <FontAwesomeIcon
                            icon={faCircleUser}
                            className="icon"
                          />
                          <p className="text  sub-title">PROFILE</p>
                        </div>
                      </Link>
                      <div className="Login" onClick={() => setModalShow(true)}>
                        <FontAwesomeIcon
                          icon={faArrowRightToBracket}
                          className="icon"
                        />
                        <p className="text sub-title">LOGOUT</p>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Link to={"/login"}>
                        <div className="Login">
                          <FontAwesomeIcon
                            icon={faArrowRightToBracket}
                            className="icon"
                          />
                          <p className="text sub-title">LOGIN</p>
                        </div>
                      </Link>{" "}
                      <Link to={"/register"}>
                        <div className="Login">
                          <FontAwesomeIcon icon={faUserPlus} className="icon" />
                          <p className="text sub-title">REGISTER</p>
                        </div>
                      </Link>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Link to={"/"}>
            <div className="mobile-logo">
              <img
                src={process.env.PUBLIC_URL + "/assests/logo/cmj-logo.png"}
                className="logo"
              />
            </div>
          </Link>

          <div className="cart-header">
            <Row className="main">
              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={3}>
                <div className="toggle" onClick={handleShow}>
                  <FontAwesomeIcon icon={faBars} />
                </div>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={9}
                className="cart-main"
              >
                <div className="Cart-shopping">
                  <div className="user-drop navbar">
                    {usertype && (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <FontAwesomeIcon icon={faUser} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="/your-orders"
                            className="small-text"
                          >
                            Order
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="/wishlist"
                            className="small-text"
                          >
                            Wish List
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="/comparelist"
                            className="small-text"
                          >
                            Compared List
                          </Dropdown.Item>
                          {/* <Dropdown.Item href="/voucher" className="small-text">
                          Vouchers
                        </Dropdown.Item> */}
                          <Dropdown.Item
                            href=""
                            onClick={() => setModalShow(true)}
                            className="small-text"
                          >
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}

                    <Link to="/cart">
                      <div className="cart">
                        <FontAwesomeIcon
                          icon={faCartShopping}
                          className="icon"
                        />
                      </div>
                    </Link>
                    <div className="search">
                      <div className="canva">
                        {["top"].map((placement, idx) => (
                          <SearchBar
                            key={idx}
                            placement={placement}
                            name={placement}
                            search={search}
                            products={products}
                            setSearch={setSearch}
                            handleNavigate={handleNavigate}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="Mobile-Offcanvas Mobile">
          <Offcanvas
            show={show}
            onHide={handleCloseone}
            className="Mobile-Offcanvas"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end desktop-offcanvas">
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/"
                    onClick={handleCloseone}
                    className="small-text"
                  >
                    HOME
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/b2bbulkorder"
                    onClick={handleCloseone}
                    className="small-text"
                  >
                    B2B BULK ORDER
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Accordion className="Mobile-drop">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="small-text">
                        SAMPLE ROLLES
                      </Accordion.Header>
                      <Accordion.Body>
                        {data?.map((item, index) => (
                          <Link
                            key={index}
                            to={`/sample-roll/${item.id}`}
                            onClick={handleCloseone}
                          // onClick={() => navigate(`/sample-roll/${item.id}`)}
                          >
                            <li className="main"> {item?.name}</li>
                          </Link>
                        ))}

                        {/* <Link to={"/b2bbulkorder"}>
                          <li className="main">Brown Tape</li>
                        </Link> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/hotdeals"
                    onClick={handleCloseone}
                    className="small-text"
                  >
                    HOT DEALS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/track-order"
                    onClick={handleCloseone}
                    className="small-text"
                  >
                    TRACK ORDER
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/faq"
                    onClick={handleCloseone}
                    className="small-text"
                  >
                    FAQ
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/help"
                    onClick={handleCloseone}
                    className="small-text"
                  >
                    HELP
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    onClick={handleCloseone}
                    className="small-text"
                  >
                    CONTACT US
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/cmj-industries"
                    onClick={handleCloseone}
                    className="small-text"
                  >
                    WHY CMJ INDUSTRIES?
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </section>

      <Permision_Delete_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {/* <LogOut show={modalShow1} onHide={() => setModalShow1(false)} message="LogOut Successfull" /> */}
    </>
  );
}

export default Header;
